import { Link } from "gatsby"
import React from "react"

import "../scss/footer.scss"
import twitter from "../images/twitter.svg"
import linkedin from "../images/linkedin.svg"
import instagram from "../images/instagram.svg"

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="f_container">
      <div className="f_upper">
        <h1 className="f_upper_title">What I can do for you</h1>
        <div className="f_list_container">
          <ul>
            <li>Art Direction</li>
            <li>UI / UX</li>
            <li>Development</li>
            <li>Interaction Design</li>
            <li>Illustration</li>
          </ul>
          <ul>
            <li>Websites</li>
            <li>Apps</li>
            <li>Games</li>
            <li>Experiences</li>
          </ul>
        </div>
      </div>

      <div className="f_lower">
        <div className="f_lower_container">
          <div className="f_contact_link">
            <Link to={"/contact"}>Contact Me &rarr;</Link>
          </div>

          <div className="f_tech_about">
            I developped this website using
            <a
              href="https://www.gatsbyjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;Gatsby
            </a>
            ,
            <a
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;ReactJS
            </a>
            &nbsp;and
            <a
              href="https://sass-lang.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;Sass
            </a>
            .
            <br />
            It is hosted by
            <a
              href="https://www.netlify.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;Netlify
            </a>
            .
          </div>

          <div className="f_bottom_row">
            <div
              className="copyright"
              style={{
                fontSize: "18px",
              }}
            >
              &copy; 2019{" "}
              <span
                style={{
                  fontFamily: "'Lexend Giga'",
                  fontSize: "18px",
                  marginLeft: "10px",
                }}
              >
                Charles Chalas
              </span>
            </div>

            <div className="f_social_media">
              <a
                href="https://www.instagram.com/chalas.ch/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Instagram logo" />
              </a>
              <a
                href="https://www.linkedin.com/in/charles-chalas-63989913a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="Linkedin logo" />
              </a>
              <a
                href="https://twitter.com/chalas_ch"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Twitter logo" />
              </a>
            </div>
          </div>

          {/*<ul className="f_links">
            <li>
              <Link to={"/about"}>About</Link>
            </li>
            <li>
              <Link to={"/work"}>Work</Link>
            </li>
            <li>
              <Link to={"/contact"}>Contact</Link>
            </li>
          </ul>*/}
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
